// Bootstrap and its default variables
@import "~bootswatch/dist/darkly/variables";
// $body-bg: #f8f8f8;

$primary : #1285ff;
$link-color : $primary;
$pagination-bg : $primary;
$pagination-disabled-bg: darken($primary, 15%);
$pagination-hover-bg: lighten($primary, 10%);

@import "node_modules/bootstrap/scss/bootstrap";
@import "~bootswatch/dist/darkly/bootswatch";

.table{
    overflow: auto;
    table-layout: auto !important;
}
.managers-select {
 // width:150px;
 color: black
}
#sort-select {
  width:150px;
}
#campaign-select {
  width:200px;
}
#agent-select {
  width:200px;
}
#size-select {
  width:100px;
}
#action-select {
  width:100px;
}

#group-select {
  width:150px;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;

  &:focus{
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  }
}

.loading-sync {
  animation: rotation 2s infinite linear;
  -webkit-animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
}